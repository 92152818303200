import React, { useContext } from "react";
import "./Intro.css";
import Vector1 from "../../img/Vector1.png";
import Vector2 from "../../img/Vector2.png";
import boy from "../../img/boy.png";
import rifnas from "../../img/rifnas.png";

import glassesimoji from "../../img/glassesimoji.png";
import thumbup from "../../img/thumbup.png";
import crown from "../../img/crown.png";
import FloatinDiv from "../FloatingDiv/FloatingDiv";
import Github from "../../img/github.png";
import LinkedIn from "../../img/linkedin.png";
import Instagram from "../../img/instagram.png";
import Facebook from "../../img/facebook2.png";

import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { useState } from "react";
import { useEffect } from "react";
import endpoint from "../../config/endpoints.config.js";

const Intro = (props) => {
  // Transition
  const transition = { duration: 2, type: "spring" };

  // const client = props.client;
  // const { client } = props.client;
  console.log(props.users, "user");

  const users = props.users;
  console.log(users, "usersss");

  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // const [fileName, setFileName] = useState(null);
  // const [imageUrl, setImageUrl] = useState(null);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  //   useEffect(() => {

  // // console.log(client?.image, "client", "client");
  //     const imageFile = client?.image;
  //     console.log(imageFile, "imageFile");
  //     setFileName(imageFile);

  //     // const fileUrl = `/clients/${filename}`;
  //     // const imageFileUrl = `${endpoint.imageUploadFolder}/clients/${fileName}`;
  //     // setImageUrl(fileUrl);

  // //  src={client?.image ? endpoint.imageUploadFolder + "/clients/" + fileName : ""}
  //     // console.log(fileUrl, "fileUrl");

  //   }, []);

  return (
    <div className="Intro" id="Intro">
      {/* left name side */}
      {users.name}
      <br />
      {users.email}
      <br />
      {users.url}
    </div>
  );
};

export default Intro;
