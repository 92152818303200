import Navbar from "./components/Navbar/Navbar";
import Intro from "./components/Intro/Intro";
import Services from "./components/Services/Services";
import "./App.css";
import Experience from "./components/Experience/Experience";
import Works from "./components/Works/Works";
import Portfolio from "./components/Portfolio/Portfolio";
import Testimonial from "./components/Testimonials/Testimonial";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import { useContext, useEffect, useState } from "react";
import { themeContext } from "./Context";
import { GetAllClientsApi, GetSingleClientApi } from "./api/Api";
import axios from "axios";
import endpoint from "./config/endpoints.config.js";

// import dispatch from "dispatch";
function App() {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  // const dispatch = useAppDispatch();

  //   useEffect(() => {
  //     document.title = "CV";  //get this dynamically
  // // export const API = axios.create({ baseURL: endpoint.baseUrl });

  //     // cvCms = process.env.REACT_APP_API_URL,
  //     // clientAccessKey = process.env.REACT_APP_FE_CLIENT_ID,
  //     // client= {},

  // // this.axios
  // //   .get(GetAllClientsApi)
  // //   .then((response) => {
  // //     console.log(response.data.client);
  // //     this.client = response.data.client;
  // //   })
  // //   .catch((error) => {
  // //     console.log(error);
  // //   });
  //     // dispatch(GetAllClientsApi);

  //     // clientAccessKey = process.env.REACT_APP_FE_CLIENT_ID,
  //     // client= {}

  //   }, [])

  // const [client, setClient] = useState(null);
  const [clients, setClients] = useState({});

  const [client, setClient] = useState({});

  const handleClientData = (data) => {
    setClient(data);
  };

  // const [imageUrl, setImageUrl] = useState(null);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    // all clients
    async function fetchAllClientsData() {
      try {
        //  const response = await axios.get("http://localhost:8000/api/admin/clients");
        const response = await GetAllClientsApi();
        setClients(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchAllClientsData();

    // single client
    async function fetchSingleClientData() {
      let clientId = endpoint.clientId;

      try {
        const response = await GetSingleClientApi(clientId);
        setClient(response.data);
        handleClientData(response.data);

        // const filename = client.image;
        // console.log(filename, "filename");

        // const fileUrl = `http://localhost:8000/api/admin/images/clients/${filename}`;
        // console.log(fileUrl, "fileUrl");
        // setImageUrl(fileUrl);
      } catch (error) {
        console.error(error);
      }
    }
    fetchSingleClientData();

    // const domain = {
    //   domain: "www.hafi.com"
    // }

    // async function fetchUserData() {
    //   axios
    //     // .post("http://localhost:8000/api/users/3")
    //     .post(`http://localhost:8000/api/users/view-by-domain?data=${data}`)
    //     .then((response) => {
    //       console.log(response, "response");
    //       setUsers(response.data.data);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
    // fetchUserData();

    async function fetchUserData(domain) {
      axios
        // .get(`http://localhost:8000/api/users/view-by-domain${domain}`)
        .get(`http://localhost:8000/api/users/view-by-domain/${domain}`)
        .then((response) => {
          console.log(response.data.data, "response");
          setUsers(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // fetchUserData('www.kasun.com');
    // const userDomain =  window.location.pathname + window.location.hostname;  // use this
    const userDomain =  window.location.hostname;  // use this

    // fetchUserData(userDomain);
    // const userDomain = window.location.host; 
    // const userDomain = window.location.pathname;  //working local
    // const userDomain = window.location.origin;

    console.log(window.location,'userDomain');
    console.log(window.location.pathname,'userDomain');

    fetchUserData(userDomain);
    
  }, []);

  // console.log(clients, "clients");
  // console.log(client, "client");
  console.log(users, "user");

  return (
    <div
      className="App"
      // props={client}
      style={{
        background: darkMode ? "black" : "",
        color: darkMode ? "white" : "",
      }}
    >
      {/* {users.map((user) => (
        <div key={user.id}>
          <p>Name: {user.name}</p>
          <p>Email: {user.email}</p>
        </div>
      ))} */}
      {/* <Navbar client={client} />*/}
      <Intro users={users} />
      {/* <Intro props={client} /> */}

      {/* <Services client={client} /> */}
      {/* <Experience client={client} /> */}

      {/* <Works client={client} /> */}
      {/* <Portfolio /> */}
      {/* {client?.show_testimonials ? <Testimonial client={client} /> : ""}
      <Contact client={client} />

      <Footer client={client} /> */}
    </div>
  );
}

export default App;
