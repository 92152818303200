import axios from "axios";

import endpoint from "../config/endpoints.config.js";

// Base URL for all apis
export const API = axios.create({ baseURL: endpoint.baseUrl });

// get all clients
export const GetAllClientsApi = () => API.get("/api/admin/clients");

// export const employeeClockOutManually = (id: any) =>
//   API.post(`/employee/clockout/manually/${id}`);

// export const GetSingleClientApi = (id) => API.get(`/api/admin/client/${id}`);
export const GetSingleClientApi = (data) => API.post("/api/admin/clients", data);
